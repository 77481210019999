import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { Icon } from 'ui-components-web';
import { IconSet } from 'ui-components-web/src/Components/Icon';
import { Label } from 'Global/styled';
import theme from 'Global/theme';
import { BackIcon, CustomerDetailsHeaderContainer, IconContainer } from './style';
function ContentHeader({ title, description }) {
    const navigate = useNavigate();
    return (_jsxs(CustomerDetailsHeaderContainer, { "$description": typeof description == 'string', children: [_jsx(BackIcon, { onClick: () => navigate('/overview'), children: _jsx(IconContainer, { children: _jsx(Icon, { dataTestId: 'back-arrow-button', iconName: IconSet.arrowBack, color: theme.colors.grayLynch }) }) }), _jsxs("div", { style: { paddingLeft: '15px' }, children: [_jsx("h2", { style: { marginBlock: '0px', fontSize: '25px' }, children: title }), description && _jsx(Label, { "$color": theme.colors.gray, children: description })] })] }));
}
export default ContentHeader;
