import styled from 'styled-components';
export const CustomerDescriptionContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: baseline;
`;
export const DescriptionBlock = styled.div `
  padding-top: ${({ $paddingTop }) => $paddingTop ?? '2em'};
  display: flex;
  flex-direction: column;
  line-height: 1;
`;
