import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
`;
export const GaugeWrapper = styled.div `
  padding-inline: 0.5em;
`;
