import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Tag } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomList, WhiteBlock } from 'ui-components-web';
import AssetsLiabilitiesSection from 'Components/AssetsLiabilitiesSection';
import CompanyData from 'Components/CompanyData';
import CostsRevenueSection from 'Components/CostsRevenueSection';
import ContentHeader from 'Components/CustomerDetailsHeader';
import FinancialFiguresCharts from 'Components/FinancialFiguresCharts';
import LiquidityChart from 'Components/LiquidityChart';
import LiquidityStatus from 'Components/LiquidityStatus';
import RecomendationsList from 'Components/RecomendationsList';
import { formatCurrency, formatDate } from 'Global/utils';
import { getCustomerDetails, getRecommendations } from 'Service/api/finInsights';
import { BlockWrapper, ChartBlockWrapper, CompanyInfoBlockWrapper, GeneralInfoBlockWrapper, RecommendationsBlockWrapper, } from './style';
function CustomerDetails() {
    const { sme_tenant_id, sme_identity_id } = useParams();
    const [data, setData] = useState();
    const [recomendationsItems, setRecomendationsItems] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        sme_tenant_id &&
            sme_identity_id &&
            getCustomerDetails(sme_tenant_id, sme_identity_id)
                .then(({ data }) => {
                setData(data);
                setIsLoading(false);
            })
                .catch((error) => console.log({ error }));
    }, []);
    useEffect(() => {
        sme_tenant_id &&
            sme_identity_id &&
            getRecommendations(sme_tenant_id, sme_identity_id)
                .then(({ data }) => {
                const { recommendations } = data;
                setRecomendationsItems(recommendations);
            })
                .catch((error) => console.log({ error }));
    }, [sme_tenant_id, sme_identity_id]);
    if (!data)
        return _jsx(_Fragment, {});
    const { sme_data, liquidity = { balances: { overall: {} } }, revenue, cost, status, liabilities, assets, benchmarking_insights, } = data;
    const companyAddress = sme_data?.identification?.company_address;
    const renderColumns = [
        {
            title: 'Kennzahl',
            dataIndex: 'key',
            key: 'key',
            render: (text) => t(`CustomerDetails.IndustryComparisonKeys.${text}`, { defaultValue: text }),
        },
        {
            title: 'Branchendurchschnitt',
            dataIndex: 'actual_value',
            key: 'actual_value',
            render: (value, record) => {
                if (record?.unit === 'EUR') {
                    return formatCurrency(value);
                }
                if (record?.unit === '%') {
                    return `${(value * 100).toFixed(1)}%`;
                }
                return value;
            },
        },
        {
            title: 'Einschätzung',
            dataIndex: 'benchmark_value',
            key: 'benchmark_value',
            render: (value, record) => {
                if (record?.unit === 'EUR') {
                    return formatCurrency(value);
                }
                if (record?.unit === '%') {
                    return `${(value * 100).toFixed(1)}%`;
                }
                return value;
            },
        },
        {
            title: 'Status',
            dataIndex: 'comparison',
            key: 'comparison',
            render: (record) => (_jsx(Tag, { color: record?.toUpperCase() === 'POSITIVE' ? 'green' : 'gold', children: record?.toUpperCase() === 'POSITIVE' ? 'Positiv' : 'Verbesserungswürdig' })),
        },
    ];
    const dataSource = benchmarking_insights?.industry_averages &&
        Object.keys(benchmarking_insights?.industry_averages).map((key) => ({
            key: key,
            comparison: benchmarking_insights.industry_averages[key]?.comparison,
            actual_value: benchmarking_insights.industry_averages[key]?.actual_value,
            benchmark_value: benchmarking_insights.industry_averages[key]?.benchmark_value,
            unit: benchmarking_insights.industry_averages[key]?.unit,
        }));
    const overall = liquidity?.balances?.overall;
    const liquidityStatus = overall?.liquidity_status;
    return (_jsxs("div", { style: { paddingBottom: '10em' }, children: [_jsx(ContentHeader, { title: sme_data?.identification?.company_name ?? '', description: sme_data?.main_industry_segment?.industry_segment_name_de ?? '' }), _jsx(Col, { span: 24, children: _jsxs(GeneralInfoBlockWrapper, { "$isMultipleChildren": !!recomendationsItems, children: [_jsxs(CompanyInfoBlockWrapper, { children: [_jsx("h2", { style: { marginTop: '0px', paddingBottom: '2px' }, children: "Unternehmensinformationen" }), _jsx(CompanyData, { phone: sme_data?.stakeholder?.phone_number, street: companyAddress?.street, city: companyAddress?.city, name: [sme_data?.stakeholder?.firstname, sme_data?.stakeholder?.lastname]
                                        .filter(Boolean)
                                        .join(' '), dateOfEstablishment: formatDate(sme_data?.identification?.foundation_date), legalForm: sme_data?.identification?.legal_form, companyName: sme_data?.identification?.company_name, taxNumber: sme_data?.identification?.ustd_id })] }), recomendationsItems && (_jsx(RecommendationsBlockWrapper, { children: _jsx(RecomendationsList, { items: recomendationsItems }) }))] }) }), _jsxs(Col, { span: 19, children: [_jsx("h2", { children: t('CustomerDetails.FinancialFigures') }), _jsxs(BlockWrapper, { "$isMultipleChildren": !!status, children: [status && _jsx(FinancialFiguresCharts, { status: status }), liquidityStatus && (_jsx(ChartBlockWrapper, { children: _jsx(LiquidityStatus, { status: liquidityStatus, title: t('CustomerDetails.LiquidityStatus') }) }))] })] }), liquidity && _jsx(LiquidityChart, { overall: liquidity }), _jsx(Col, { span: 19, children: _jsxs(BlockWrapper, { "$isMultipleChildren": !!(assets && liabilities), "$customStyles": { paddingTop: '1em' }, children: [revenue && cost && (_jsx(ChartBlockWrapper, { children: _jsx(CostsRevenueSection, { revenue: revenue, cost: cost }) })), assets && liabilities && (_jsx(ChartBlockWrapper, { children: _jsx(AssetsLiabilitiesSection, { assets: assets, liabilities: liabilities }) }))] }) }), dataSource && (_jsx(Col, { span: 19, style: { marginTop: '4em' }, children: _jsx(WhiteBlock, { style: { paddingBottom: '5em', marginTop: '2em' }, children: _jsx(CustomList, { title: t('CustomerDetails.IndustryComparison'), data: dataSource, columns: renderColumns }) }) }))] }));
}
export default CustomerDetails;
