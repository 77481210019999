var Sizes;
(function (Sizes) {
    Sizes["xs"] = "480px";
    Sizes["md"] = "768px";
    Sizes["lg"] = "992px";
})(Sizes || (Sizes = {}));
const theme = {
    colors: {
        darkGreen: '#00b679',
        primary: '#0066b3',
        blueCorolya: '#1677ff',
        lightGreen: '#E0F4E4',
        paleBlue: '#BAE7FF',
        lightPrimary: '#1890FF',
        darkPrimary: '#096DD9',
        white: '#ffffff',
        lightGray: '#FAFAFA',
        bubbles: '#E6F7FF',
        darkBlue: '#0504aa',
        error: '#FF3141',
        black: '#000000',
        mediumGray: '#c0c0c0',
        green: '#52C41A',
        darkBubbles: '#40A9FF',
        blueChambray: '#e9edf5',
        grayLynch: '#677c92',
        gray: '#00000073',
        mediumBlue: '#0050B3',
        borderColor: '#c7cbce',
        nightBlue: '#0F2364',
        lightBlue: '#BFD9EC',
        lightGrayishBlue: '#F7F9FC',
        orange: '#ff6600',
        orange050: '#FFBF3A',
        darkOrange: '#e75c00',
        lightOrange: '#FF8F1F',
        link: '#1890FF',
        success: '#00B578',
        warning: '#FFBB36',
        note: '#999999',
        darkCharcoal: '#333333',
        paleGray: '#f9fbfd',
    },
    fonts: {
        roboto: "'Roboto', sans-serif",
    },
    fontSizes: {
        small: '12px',
        xsmall: '14px',
        medium: '16px',
        xmedium: '18px',
        large: '24px',
        xlarge: '36px',
        h1: '1.8rem',
        h2: '1.4rem',
        h3: '1.17rem',
        h4: '1rem',
        h5: '0.83rem',
        h6: '0.67rem',
    },
    fontWeight: {
        medium: '400',
        bold: '800',
    },
    media: {
        small: `(min-width: ${Sizes.xs})`,
        medium: `(min-width: ${Sizes.md})`,
        large: `(min-width: ${Sizes.lg})`,
    },
};
export default theme;
