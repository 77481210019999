import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CustomAlert, ExternalLink } from 'ui-components-web';
import InputField from 'ui-components-web/src/Components/Input/InputField';
import VolksBankLogo from 'Assets/Volksbanken_logo.svg';
import ContactUsModal from 'Components/ContactUsModal';
import { generateRules } from 'Global/rules';
import { Label } from 'Global/styled';
import { RULES } from 'Global/types';
import { loginWithEmail } from 'Service/api/finInsights';
import { ContentContainer, LoginAlertContainer, LoginBlock, LoginContainer } from './style';
const Login = () => {
    const { control, formState: { errors }, getValues, handleSubmit, watch, } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const email = watch('email');
    const handleOnKeyDown = (e) => {
        if (isLoading)
            return;
        if (e.key === 'Enter') {
            handleLogin();
        }
    };
    const handleLogin = handleSubmit(() => {
        setIsLoading(true);
        const { email } = getValues();
        loginWithEmail(email)
            .catch((error) => console.log({ error }))
            .finally(() => {
            setShowFeedbackMessage(true);
            setIsLoading(false);
        });
    });
    useEffect(() => {
        if (showFeedbackMessage) {
            setShowFeedbackMessage(false);
        }
    }, [email]);
    return (_jsxs(LoginContainer, { children: [_jsxs("div", { style: { alignItems: 'baseline' }, children: [_jsx("img", { src: VolksBankLogo }), _jsxs(LoginBlock, { children: [_jsxs(ContentContainer, { children: [_jsxs("h4", { children: [" ", t('LoginPage.Title'), " "] }), _jsx(Label, { "$fontSize": '15px', children: t('LoginPage.Description') }), _jsx(InputField, { control: control, name: 'email', rules: generateRules([RULES.REQUIRED, RULES.EMAIL]), label: 'Email', onKeyDown: handleOnKeyDown, errors: errors }), _jsx("div", { style: { paddingTop: '1em' }, children: _jsx(Button, { "data-test-id": 'login-submit-button', loading: isLoading, disabled: isLoading, text: t('LoginPage.LoginCTA'), type: 'primary', onClick: handleLogin }) })] }), _jsx(ExternalLink, { text: t('LoginPage.ContactSupportLink'), onClick: () => setIsOpen(true) })] })] }), showFeedbackMessage && (_jsx(LoginAlertContainer, { children: _jsx(CustomAlert, { description: t('LoginPage.FeedbackMessage.Description'), status: t('LoginPage.FeedbackMessage.Title'), type: 'success' }) })), _jsx(ContactUsModal, { isOpen: isOpen, setIsOpen: setIsOpen })] }));
};
export default Login;
