import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import CustomDropDown from 'ui-components-web/src/Components/Dropdown';
import { ActionOptions } from 'Global/types';
import { formatDate } from 'Global/utils';
import { updateCustomerStatus } from 'Service/api/finInsights';
import { ListContainer, TextRowContent } from './style';
const defaultSort = (a, b) => {
    const { company_name } = a;
    return company_name.localeCompare(b.company_name);
};
export const Sorter = {
    DEFAULT: defaultSort,
};
const handleUpdateStatus = (customer_id, value) => updateCustomerStatus(customer_id, value.value)
    .then((result) => console.log({ result }))
    .catch((error) => console.log({ error }));
const renderSuggestedAction = (text) => {
    switch (text) {
        case 'CONTACT':
            return 'Kontaktieren';
        case 'OBSERVE':
            return 'Beobachten';
        case 'WAIT':
            return 'Abwarten';
    }
};
const renderColumns = () => [
    {
        title: t('CustomersTable.Customers'),
        dataIndex: 'company_name',
        key: 'company_name',
        render: (text, record) => {
            const { owner_name, account_number, sme_tenant_id, sme_identity_id } = record;
            return (_jsxs("ul", { style: { listStyleType: 'none', padding: 0 }, children: [_jsx(TextRowContent, { children: text }, text), _jsx(TextRowContent, { "$isGray": true, children: owner_name }, owner_name), _jsxs(TextRowContent, { "$isGray": true, children: ["AN - ", account_number] }, account_number)] }));
        },
        sorter: {
            compare: Sorter.DEFAULT,
            multiple: 3,
        },
    },
    {
        title: 'FinCheck Status',
        dataIndex: 'fincheck_status',
        key: 'fincheck_status',
        render: (status, record) => {
            const { fincheck_status_date } = record;
            return (_jsxs(ListContainer, { children: [_jsx(TextRowContent, { children: t(`OverviewPage.FinCheckStatus.${status}`, { defaultValue: status }) }, status), _jsx(TextRowContent, { "$isGray": true, children: formatDate(fincheck_status_date) }, fincheck_status_date)] }));
        },
    },
    {
        title: t('CustomersTable.ProductRecommendation'),
        dataIndex: 'product_recommendations',
        key: 'product_recommendations',
        render: (record) => record?.length > 0 &&
            record.map((item) => _jsx(TextRowContent, { children: item.product }, item)),
    },
    {
        title: t('CustomersTable.RecommendedAction'),
        key: 'recommended_action',
        dataIndex: 'recommended_action',
        render: (text, record) => {
            const { recommended_action_date } = record;
            return (_jsxs(ListContainer, { children: [_jsx(TextRowContent, { children: renderSuggestedAction(text) }, text), _jsx(TextRowContent, { "$isGray": true, children: recommended_action_date && recommended_action_date.split('T')[0] }, recommended_action_date)] }));
        },
    },
    {
        title: t('CustomersTable.status'),
        key: 'action',
        render: (text, record) => {
            const { last_action_taken } = record;
            return (_jsx("div", { "data-test-id": 'action-drop-down', style: { width: '10em' }, onClick: (e) => e.stopPropagation(), children: _jsx(CustomDropDown, { width: '100%', defaultValue: last_action_taken, options: ActionOptions, placeholder: 'Aktion', callback: (value) => record?.sme_tenant_id && handleUpdateStatus(record?.sme_tenant_id, value) }) }));
        },
    },
];
export default renderColumns;
