import styled from 'styled-components';
export const LoginContainer = styled.div `
  background-color: ${({ theme }) => theme.colors.nightBlue};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const LoginBlock = styled.div `
  background-color: ${({ theme }) => theme.colors.white};
  width: 500px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 0.5em;
`;
export const ContentContainer = styled.div `
  padding: 5px 15px 0px 15px;
`;
export const SearchContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: -2em;
`;
export const InvitePortalContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const FormContainer = styled.div `
  width: 30em;
`;
export const InfoBoxesContainer = styled.div `
  display: flex;
  flex-direction: row;
`;
export const ListContainer = styled.div `
  padding-bottom: 5em;
`;
export const AlertContainer = styled.div `
  margin-top: 1.5em;
`;
export const LoginAlertContainer = styled.div `
  margin-top: 1.5em;
  width: 500px;
`;
export const BlockWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ $isMultipleChildren }) => $isMultipleChildren ? 'space-between' : 'flex-end'};

  ${({ $customStyles }) => $customStyles && { ...$customStyles }};
`;
export const ChartBlockWrapper = styled.div `
  flex: 1 1 50%;
  max-width: 48%;
`;
export const RecommendationsBlockWrapper = styled.div `
  flex: 1 1 25%;
  max-width: 25%;
`;
export const GeneralInfoBlockWrapper = styled.div `
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${({ $isMultipleChildren }) => $isMultipleChildren ? 'space-between' : 'flex-start'};
  width: 100%;
`;
export const CompanyInfoBlockWrapper = styled.div `
  flex: 1 1 70%;
  max-width: 70%;
`;
