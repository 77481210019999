import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col } from 'antd';
import { t } from 'i18next';
import { useMemo } from 'react';
import { GradientGaugeChart, WhiteBlock } from 'ui-components-web';
import { Label } from 'Global/styled';
import { Container, GaugeWrapper } from './style';
function FinancialFiguresCharts({ status }) {
    const renderText = useMemo(() => {
        switch (status) {
            case 'critical':
                return 'KRITISCH';
            case 'uncritical':
                return 'UNKRITISCH';
            default:
                return 'ZU BEOBACHTEN';
        }
    }, [status]);
    const renderValue = useMemo(() => {
        switch (status) {
            case 'critical':
                return 20;
            case 'uncritical':
                return 90;
            default:
                return 50;
        }
    }, [status]);
    return (_jsx(Col, { span: 12, children: _jsx(Container, { children: _jsxs(WhiteBlock, { children: [_jsx(Label, { "$fontSize": '16px', children: t('CustomerDetails.FinancialHealth') }), _jsx(GaugeWrapper, { children: _jsx(GradientGaugeChart, { descriptionFontSize: '16px', width: '33em', height: '15em', valueFontSize: '24px', value: renderValue, text: renderText, description: 'Ihr Finanz Check' }) })] }) }) }));
}
export default FinancialFiguresCharts;
