import { Col } from 'antd';
import styled from 'styled-components';
export const ErrorMessage = styled.p `
  color: ${({ theme }) => theme.colors.error};
`;
export const BlueHeadline = styled.label `
  font-size: ${({ theme }) => theme.fontSizes.h2};
  color: ${({ theme }) => theme.colors.primary};
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding-bottom: 0.5em;
`;
export const Label = styled.label `
  font-size: ${({ $fontSize, theme }) => ($fontSize ? $fontSize : theme.fontSizes.medium)};
  color: ${({ $color, theme }) => ($color ? $color : theme.colors.black)};
  display: inline-block;
  padding-bottom: 10px;
  position: relative;
  font-weight: ${({ $isBold, theme }) => $isBold && theme.fontWeight.bold};
  &::before {
    content: ${({ required }) => (required ? '"*  "' : '""')};
    color: ${({ theme }) => theme.colors.error};
  }
`;
export const CustomLabel = styled.label `
  font-size: ${({ theme }) => theme.fontSizes.medium};
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
  text-align: start;
  position: relative;
  &::before {
    content: ${({ required }) => (required ? '"*  "' : '""')};
    color: ${({ theme }) => theme.colors.error};
  }
`;
export const ConsentLink = styled.a `
  color: ${({ theme, $color }) => $color ?? theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: underline;
  padding-inline: 3px;
`;
export const ConsentText = styled.div `
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;
`;
export const Container = styled.div `
  display: inline-block;
  width: 100%;
  margin: 10px auto;
`;
export const CalculatorBottomBlockWrapper = styled.div `
  padding-top: 2em;
`;
export const CalculatorCol = styled(Col) `
  padding-top: 2em;
`;
export const Caption = styled.p `
  height: 3em;
  line-height: 1.4em;
  padding-left: 47px;
  padding-right: 47px;
  font-size: ${({ theme }) => theme.fontSizes.h2};
  text-align: center;
  line-height: 1.5em;
`;
export const ValuesBlock = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2em;
`;
