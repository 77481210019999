import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from 'react-router-dom';
import Layout from 'Components/Layout';
import CustomerDetails from 'Pages/CustomerDetails';
import InvitePortal from 'Pages/InvitePortal';
import Overview from 'Pages/Overview';
const ProtectedRouter = () => {
    return (_jsx(Layout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: '/overview', element: _jsx(Overview, {}) }), _jsx(Route, { path: '/customer/details/:sme_tenant_id/:sme_identity_id', element: _jsx(CustomerDetails, {}) }), _jsx(Route, { path: '/invite/portal', element: _jsx(InvitePortal, {}) })] }) }));
};
export default ProtectedRouter;
