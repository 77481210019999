import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { CustomAlert, WhiteBlock } from 'ui-components-web';
function LiquidityStatus({ title = 'Liquidity Status', status = 'uncritical', description = 'Ihre Liquidität entwickelt sich gut. Unten stehen weitere mögliche Maßnahmen für Sie bereit.', }) {
    const renderColor = useMemo(() => {
        switch (status) {
            case 'critical':
                return 'error';
            case 'uncritical':
                return 'success';
            default:
                return 'warning';
        }
    }, [status]);
    const renderText = useMemo(() => {
        switch (status) {
            case 'critical':
                return 'KRITISCH';
            case 'uncritical':
                return 'UNKRITISCH';
            default:
                return 'ZU BEOBACHTEN';
        }
    }, [status]);
    return (_jsxs(WhiteBlock, { style: { height: '14em' }, children: [_jsx("h2", { children: title }), _jsx(CustomAlert, { status: renderText, description: description, type: renderColor })] }));
}
export default LiquidityStatus;
