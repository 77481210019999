import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { ChartIndicators, ColoredDotLabel, WhiteBlock } from 'ui-components-web';
import AssetsLiabilitiesChart from 'Components/AssetsLiabilitiesChart';
import theme from 'Global/theme';
import { formatCurrency, formatDate } from 'Global/utils';
import { IndicatorsWrapper } from './style';
const getFieldValue = (assets, liabilities, field) => {
    const assetsData = assets.data;
    const liabilitiesData = liabilities.data;
    const currentAssets = assetsData.sort((a, b) => b.year - a.year)?.[0];
    const currentliabilities = liabilitiesData.sort((a, b) => b.year - a.year)?.[0];
    if (field === 'created_at') {
        return formatDate(currentAssets?.created_at ?? currentliabilities?.created_at);
    }
    if (field === 'value') {
        if (currentAssets && currentliabilities && currentAssets?.year === currentliabilities?.year) {
            return formatCurrency(currentAssets?.value - currentliabilities?.value);
        }
        return '---';
    }
    if (field === 'percentage_change') {
        if (currentAssets && currentliabilities && currentAssets?.year === currentliabilities?.year) {
            const lastYearAssets = assetsData.sort((a, b) => b.year - a.year)?.[1];
            const lastYearLiabilities = liabilitiesData.sort((a, b) => b.year - a.year)?.[1];
            if (lastYearAssets &&
                lastYearLiabilities &&
                lastYearAssets?.year === lastYearLiabilities?.year) {
                const ratio = (lastYearAssets?.value - lastYearLiabilities?.value) /
                    (currentAssets?.value - currentliabilities?.value);
                if (isNaN(ratio)) {
                    return '---';
                }
                return formatCurrency(ratio * 100, 'decimal');
            }
            return '---';
        }
    }
    return '';
};
export default function AssetsLiabilitiesSection({ assets, liabilities }) {
    return (_jsxs(WhiteBlock, { style: { paddingBottom: '5em', padding: '1em', marginTop: '2em' }, children: [_jsx(IndicatorsWrapper, { children: _jsx(ChartIndicators, { labelFontSize: '17px', title: `${t('CustomerDetails.Assets')} vs. ${t('CustomerDetails.Liabilities')}`, amount: getFieldValue(assets, liabilities, 'value'), lastUpdate: `${t('CustomerDetails.LastUpdate')} ${assets ? getFieldValue(assets, liabilities, 'created_at') : ''}`, status: assets.status, percentage: getFieldValue(assets, liabilities, 'percentage_change'), text: t('CustomerDetails.CurrentYear') }) }), _jsx(AssetsLiabilitiesChart, { assets: assets.data, liabilities: liabilities.data }), _jsxs("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }, children: [_jsx(ColoredDotLabel, { fontSize: '15px', text: t('CustomerDetails.Assets'), color: theme.colors.orange }), _jsx(ColoredDotLabel, { fontSize: '15px', text: t('CustomerDetails.Liabilities'), color: theme.colors.orange050 })] })] }));
}
