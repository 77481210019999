import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { WhiteBlock } from 'ui-components-web';
import { Label } from 'Global/styled';
import ROUTES from 'Router/routes';
import { MenuContent } from './style';
function Menu({ callback, setModalOpen }) {
    const navigate = useNavigate();
    const handleNavigate = (url) => {
        callback && callback();
        if (url === '')
            setModalOpen();
        else
            navigate(url);
    };
    return (_jsx(WhiteBlock, { style: { position: 'absolute', right: '1em', top: '3em', zIndex: 100 }, children: _jsxs(MenuContent, { children: [_jsx(Label, { style: { cursor: 'pointer' }, onClick: () => handleNavigate(ROUTES.OVERVIEW), children: t('BurgerMenu.BackToOverview') }), _jsx(Label, { style: { cursor: 'pointer' }, onClick: () => handleNavigate(''), children: t('BurgerMenu.ContactSupport') }), _jsx(Label, { style: { cursor: 'pointer' }, onClick: () => {
                        localStorage.clear();
                        handleNavigate(ROUTES.LOGIN);
                    }, children: t('BurgerMenu.Logout') })] }) }));
}
export default Menu;
