import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col } from 'antd';
import { t } from 'i18next';
import { AreaChart, ChartIndicators, ColoredDotLabel, WhiteBlock } from 'ui-components-web';
import { IndicatorsWrapper } from 'Components/CostsRevenueSection/style';
import theme from 'Global/theme';
import { formatCurrency, formatDate } from 'Global/utils';
import { DotsContainer } from './style';
function LiquidityChart({ overall }) {
    const renderPercentage = (data) => {
        const d = new Date();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        const currentDate = `${year}-${month}-15`;
        const result = data.find((item) => item.month === currentDate);
        return result ? result.percentage_change?.toString() || 'No data' : 'No data';
    };
    const generateYTickValues = (data) => {
        if (data) {
            const balances = data.map((item) => item.average_monthly_balance);
            const minBalance = Math.min(...balances);
            const maxBalance = Math.max(...balances);
            if (minBalance === maxBalance) {
                return [minBalance];
            }
            const range = maxBalance - minBalance;
            const yTickValues = [];
            const step = Math.ceil(range / 2);
            for (let i = 0; i <= maxBalance; i += step) {
                yTickValues.push(i);
            }
            yTickValues.push(maxBalance * 1.1);
            return yTickValues;
        }
        return [10000, 20000, 30000, 40000, 50000];
    };
    return (_jsx(Col, { span: 19, style: { top: '2em' }, children: _jsxs(WhiteBlock, { style: { paddingBottom: '2em' }, children: [overall.balances.overall && (_jsx(IndicatorsWrapper, { children: _jsx(ChartIndicators, { title: t('CustomerDetails.Liquidity'), amount: formatCurrency(overall.balances.overall.current_liquidity?.current_balance), lastUpdate: `${t('CustomerDetails.LastUpdate')} ${formatDate(overall.balances.overall.current_liquidity?.current_date)}`, status: overall.balances.overall.liquidity_status, percentage: renderPercentage(overall.balances.overall.monthly_data), text: t('CustomerDetails.CurrentMonth') }) })), overall.balances && (_jsx(AreaChart, { data: overall.balances.overall.monthly_data, yTickValues: generateYTickValues(overall.balances.overall.monthly_data), width: 700, height: 300 })), _jsxs(DotsContainer, { children: [_jsx("div", { style: { paddingRight: '2em' }, children: _jsx(ColoredDotLabel, { fontSize: '17', text: t('CustomerDetails.Liquidity'), color: theme.colors.success }) }), _jsx(ColoredDotLabel, { fontSize: '17', text: t('CustomerDetails.Prognose'), color: 'rgba(0, 181, 120, 0.5)' })] })] }) }));
}
export default LiquidityChart;
