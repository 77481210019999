import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col } from 'antd';
import { t } from 'i18next';
import { RecommendationRow } from 'ui-components-web';
import { v4 as uuidv4 } from 'uuid';
function RecomendationsList({ items }) {
    const renderRow = () => items.map((row) => (_jsx(Col, { style: { paddingBlock: '0.5em' }, children: _jsx(RecommendationRow, { product: row.product, product_type: row.sub_category === 'secure_credit' ? 'Besicherter Kredit' : 'Unbesicherter Kredit', rating: row.affinity_score + '%' }) }, uuidv4())));
    return (_jsxs("div", { children: [_jsx("h2", { style: { marginTop: '0px', paddingBottom: '2px' }, children: t('CustomerDetails.ProductRecommendation') }), _jsx("div", { style: { height: '100%' }, children: renderRow() })] }));
}
export default RecomendationsList;
